import { authRoles } from 'app/auth/authRoles';



export const navigations = [
  // {
  //   name: 'Dashboards',
  //   icon: 'dashboard',
  //   children: [
  //     { name: 'General', icon: 'dashboard', path: '/dashboard/default', iconText: 'CL', auth: authRoles.sa }, // ONLY SUPER ADMIN(SA) CAN ACCESS
  //     { name: 'Analytics', icon: 'analytics', path: '/dashboard/analytics', iconText: 'VC', auth: authRoles.admin },  // ONLY SUPER ADMIN(SA) AND ADMIN CAN ACCESS
  //     { name: 'Alternative', icon: 'analytics', path: '/dashboard/alternative', iconText: 'NC' },
  //     { name: 'Inventory Management', icon: 'store', path: '/dashboard/inventory-management', iconText: 'NC' },
  //   ],    
  // },
  {
    name: 'CRM',
    icon: 'folder',
    children: [
      { name: 'Clientes', icon: 'people', path: '/clientes', iconText: 'CL' },
      {
        name: 'Contratos',
        icon: 'money',
        children: [
          { name: 'Aceite Digital', icon: 'folder', path: '/contratos' },
        ]
      },
    ],
  },
  {
    name: 'Fila',
    icon: 'handshake-outline',
    children: [
      { name: 'Emitir Senha', icon: 'pin', path: '/emitir-senha', iconText: 'ES' },
      { name: 'Monitor Atendimentos', icon: 'monitor-heart', path: '/monitor-atendimentos', iconText: 'MA' },
      { name: 'Meus Atendimentos', icon: 'face-agent', path: '/meus-atendimentos', iconText: 'A1' },
      { name: 'Instalação', icon: 'handyman', path: '/instalacao', iconText: 'OP' },
      {
        name: 'Cadastro', icon: 'folder', icontext: 'C5',
        children: [
          { name: 'Paineis', icon: 'monitor', path: '/paineis', iconText: 'PA' },
          { name: 'Filas', icon: 'analytics', path: '/filas', iconText: 'FI' },
          { name: 'Guichês', icon: 'computer', path: '/guiches', iconText: 'G1' },
          { name: 'Técnicos', icon: 'engineering', path: '/tecnicos', iconText: 'T1' },
          { name: 'Serviços', icon: 'money', path: '/servicos', iconText: 'S1' },
          { name: 'Tickets de Abono', icon: 'sell', path: '/tickets-abono', iconText: 'TA' },
        ]
      }
    ]
  },
  {
    name: 'Cadastro',
    icon: 'folder',
    children: [
      { name: 'Estabelecimentos', icon: 'people', path: '/estabelecimentos', iconText: 'E1' },
      { name: 'Unidades', icon: 'factory', path: '/unidades', iconText: 'U1' },
      { name: 'Planos', icon: 'money', path: '/planos', iconText: 'PL' },
    ],
  },
  {
    name: 'Ferramentas',
    icon: 'handyman',
    children: [
      { name: 'Conf. ID RaioGPS e Service', icon: 'folder', path: '/integrador/raiogps' },
      { name: 'Conf. de Integração', icon: 'folder', path: '/integrador/conferencia' },
    ],
  },
  {
    name: 'Segurança',
    icon: 'security',
    children: [
      { name: 'Gestão de Acessos', icon: 'manage_accounts', path: '/usuarios', iconText: 'GA', auth: authRoles.admin },
    ]
  },
  {
    name: 'Programação',
    icon: 'calendar_today',
    children: []
  },
  {
    name: 'Ponto Raio',
    icon: 'calendar_month',
    children: [
      { name: 'Dashboard', icon: 'dashboard', path: '/ponto-raio/dashboard', iconText: 'DA', gruposPermitidos: [1] },
      { name: 'Colaboradores', icon: 'people', path: '/colaboradores', iconText: 'CO', gruposPermitidos: [1] },
      { name: 'Registro de Ponto', icon: 'event_note', path: '/registros-de-pontos', iconText: 'RP', gruposPermitidos: [1, 20] },
      { name: 'Gerenciamento de Ponto', icon: 'shield_person', path: '/gerenciamento-de-ponto', iconText: 'GP', gruposPermitidos: [1] },
      { name: 'Planejamento de Férias', icon: 'beach_access', path: '/programacao-de-ferias', iconText: 'PF', gruposPermitidos: [1] },
      { name: 'Banco de Horas', icon: 'schedule_send', path: '/pedidos-de-bancos-de-horas', iconText: 'BH', gruposPermitidos: [1] },
      { name: 'Pedidos de Licenças', icon: 'event_note', path: '/pedidos-de-licencas', iconText: 'PL', gruposPermitidos: [1] },
      { name: 'Ausencias', icon: 'event_busy', path: '/ausencias', iconText: 'AU', gruposPermitidos: [1] },
      { name: 'Lançamentos', icon: 'event_note', path: '/lancamentos', iconText: 'LA', gruposPermitidos: [1] },
      { name: 'iFood Benefícios', icon: 'file_download', path: '/ifood-beneficios', iconText: 'IF', gruposPermitidos: [1] },
      {
        name: 'Cadastros', iconText: 'CA', children: [
          { name: 'Cargos', icon: 'manage_accounts', path: '/cargos', iconText: 'CA', gruposPermitidos: [1] },
          { name: 'Horários', icon: 'schedule', path: '/horarios', iconText: 'HO', gruposPermitidos: [1] },
          { name: 'Lojas', icon: 'store', path: '/lojas', iconText: 'LO', gruposPermitidos: [1] },
          { name: 'Departamentos', icon: 'assignment_ind', path: '/departamentos', iconText: 'FE', gruposPermitidos: [1] },
          { name: 'Setores', icon: 'psychology', path: '/setores', iconText: 'SE', gruposPermitidos: [1] },
          { name: 'Feriados', icon: 'calendar_today', path: '/feriados', iconText: 'FE', gruposPermitidos: [1] },
          { name: 'Benefícios', icon: 'card_giftcard', path: '/beneficios', iconText: 'BE', gruposPermitidos: [1] },
          { name: 'Categorias de Lançamentos', icon: 'category', path: '/categorias-lancamentos', iconText: 'CL', gruposPermitidos: [1] },
        ], gruposPermitidos: [1]
      },
      {
        name: 'Relatórios', iconText: 'RE', children: [
          { name: 'Horas Trabalhadas', icon: 'analytics', path: '/relatorios/horas-trabalhadas', iconText: 'HT', gruposPermitidos: [1] },
          { name: 'Banco de Horas', icon: 'analytics', path: '/relatorios/banco-de-horas', iconText: 'BH', gruposPermitidos: [1] },
          { name: 'Faltas', icon: 'analytics', path: '/relatorios/faltas', iconText: 'FA', gruposPermitidos: [1] },
          { name: 'Ocorrências', icon: 'analytics', path: '/relatorios/ocorrencias', iconText: 'AT', gruposPermitidos: [1] },
          { name: 'Licenças', icon: 'analytics', path: '/relatorios/licencas', iconText: 'LI', gruposPermitidos: [1] },
          { name: 'Abonos', icon: 'analytics', path: '/relatorios/abonos', iconText: 'AB', gruposPermitidos: [1] },
        ], gruposPermitidos: [1]
      },
    ]
  },
];

export const getfilteredNavigations = (navList = [], role) => {
  return navList.reduce((array, nav) => {
    if (nav.auth && nav.auth.includes(role)) {
      array.push(nav);
    } else {
      if (nav.children) {
        nav.children = getfilteredNavigations(nav.children, role);
        array.push(nav);
      }
      array.push(nav);
    }
    return array;
  }, []);
};
